.work__filters{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: var(--mb-2);
}

.work__item{
    color: var(--title-color);
    padding: 0.25rem 0.75rem;
    font-weight: var(--font-medium);
    border-radius: 0.5rem;
    text-transform: capitalize;
    cursor: pointer;
}

.work__item:hover{
    background-color: var(--title-color);
    color: var(--container-color);
}

.work__container{
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    justify-content: center;
}

.work__card{
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.25rem;
    border-radius: 1rem;
}

.work__img{
    width: 295px;
    border-radius: 1rem;
    margin-bottom: var(--mb-1);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.work__title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-5);
}

.work__button{
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
}

.work__button-icon{
    font-size: 1rem;
    transition: 0.4s;
    transition: transform 0.4s ease;
}

.work__button:hover .work__button-icon{
    transform: translateX(0.25rem);
}

/* Active Work */

.active-work{
    background-color: var(--title-color);
    color: var(--container-color);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .work__container{
        gap: 1.25rem;
    }

    .work__card{
        padding: 1rem;
    }

    .work__img{
        margin-bottom: 0.75rem;
    }

    .work__title{
        margin-bottom: 0.25rem;
    }
}

  /* For medium devices */
  @media screen and (max-width: 768px) {
   
    .work__container{
        grid-template-columns: max-content;
    }
  }
  
  @media screen and (max-width: 576px) {
    .work__container{
        grid-template-columns: 1fr;
    }

    .work__img{
        width: 100%;
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {

    .work__item{
        font-size: var(--small-font-size);
    }

    .work__filters{
        column-gap: 0.25rem;
    }
  }